import { FC } from 'react';

import styled from '@emotion/styled';
import { FAQ } from 'components/LandingPages/FAQ';

import { Advantages } from './Advantages';
import { Row, RowContent } from './shared';

const CustomRow = styled(Row)`
	background-color: #edf0f4;
`;

export const WhitePart: FC = () => (
	<CustomRow>
		<RowContent>
			<Advantages />
			<FAQ onLightBackground />
		</RowContent>
	</CustomRow>
);
