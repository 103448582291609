import { FC } from 'react';

import { Stack, Typography, useMediaQuery } from '@mui/material';
import Image from 'next/image';

import { muiTheme } from '@fllite-fe/shared/src/muiTheme';

import { AdvantageProps } from './Advantages';

const AdvantagesImage: FC<AdvantageProps> = ({ imageUrl, imageWidth, imageHeight, title, id }) => (
	<Image
		src={imageUrl}
		width={imageWidth}
		height={imageHeight}
		alt={title}
		data-testid={`homepage-advantages-item-${id}-image`}
		quality={100}
	/>
);

export const AdvantagesItem: FC<AdvantageProps> = (props) => {
	const isDesktopMatch = useMediaQuery(muiTheme.breakpoints.up('sm'));

	const { title, content, id } = props;

	return (
		<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ marginBottom: '64px' }}>
			{!isDesktopMatch && <AdvantagesImage {...props} />}
			<Stack direction="column" spacing={2}>
				<Typography
					variant="h4"
					sx={{
						fontSize: isDesktopMatch ? '24px' : '20px',
						fontWeight: 600,
					}}
					data-testid={`homepage-advantages-item-${id}-title`}
				>
					{title}
				</Typography>
				<Typography
					variant="body1"
					sx={{
						fontSize: '20px',
						lineHeight: '44px',
					}}
					data-testid={`homepage-advantages-item-${id}-content`}
				>
					{content}
				</Typography>
			</Stack>
			{isDesktopMatch && <AdvantagesImage {...props} />}
		</Stack>
	);
};
