import { FC } from 'react';

import styled from '@emotion/styled';
import { BenefitBox, BenefitBoxProps } from 'components/LandingPages/BenefitBox';
import Link from 'next/link';

import { scrollToTarget } from '@fllite-fe/shared/src/utils/scrollToTarget';

import { ReadMoreLink } from './shared';

interface ItemProps extends BenefitBoxProps {
	id: string;
}

const Boxes = styled('section')`
	display: flex;
	color: ${({ theme }) => theme.colors.baseFontColor};
	justify-content: space-between;
	flex-direction: column;
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
	}
`;

const MoreAdvantages = styled('div')`
	font-size: 14px;
	${({ theme }) => theme.breakpoints.up('md')} {
		margin-bottom: 64px;
	}
`;

const items: ItemProps[] = [
	{
		id: '1',
		icon: 'secure',
		title: 'Secure Booking',
		description: 'Easily and securely book private flights through a single, easy-to-use platform',
		testId: 'article-1',
	},
	{
		id: '2',
		icon: 'affordable-white',
		title: 'Seamless Pricing',
		description: (
			<>
				No hidden fees.
				<br />
				The prices you see on Fllite are final
			</>
		),
		testId: 'article-2',
	},
	{
		id: '3',
		icon: 'pricing-white',
		title: 'Join Shared Flights',
		description: 'Save money on private flights by splitting costs with others',
		testId: 'article-3',
	},
];

export const Benefits: FC = () => {
	const handleScrollToAdvantages = (e) => {
		e.preventDefault();
		scrollToTarget('advantages');
	};

	return (
		<>
			<Boxes>
				{items.map(({ id, icon, title, description, testId }) => (
					<BenefitBox
						key={id}
						icon={icon}
						title={title}
						description={description}
						testId={testId}
					/>
				))}
			</Boxes>
			<MoreAdvantages>
				<Link href="#advantages" passHref legacyBehavior data-testid="homepage-advantages-link">
					<ReadMoreLink onClick={handleScrollToAdvantages}>
						Read more about Fllite’s Advantages
					</ReadMoreLink>
				</Link>
			</MoreAdvantages>
		</>
	);
};
