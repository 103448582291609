import { FC } from 'react';

import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { RequestAFlightButton } from 'components/LandingPages/RequestAFlightButton';
import { Element } from 'react-scroll';

import { AdvantagesItem } from './AdvantagesItem';
import { SubTitle } from './shared';

export interface AdvantageProps {
	title: string;
	content: string;
	imageUrl: string;
	imageWidth?: number;
	imageHeight: number;
	id: string;
}

const CustomSubTitle = styled(SubTitle)`
	color: #002553;
	text-align: left;
	${({ theme }) => theme.breakpoints.up('md')} {
		text-align: center;
	}
`;

const advantageItems = [
	{
		title: 'Secure Booking',
		content:
			'Fllite offers a secure platform for booking and managing charter flights in a few simple steps. Only verified users are allowed to see pricing and access charter quotes.',
		imageUrl: '/assets/homepage/advantages-secure-booking.webp',
		imageWidth: 308,
		imageHeight: 254,

		id: '1',
	},
	{
		title: 'Seamless Booking with Real-time Pricing',
		content:
			'There is no need to exchange emails to get a charter quote for a private flight. We offer real-time pricing for selected aircraft, with no hidden fees, and also access to thousands of charters operators with only one click.',
		imageUrl: '/assets/homepage/advantages-seamless-booking.webp',
		imageWidth: 306,
		imageHeight: 272,
		id: '2',
	},
	{
		title: 'Join Shared Flights',
		content:
			'Up for an adventure? Fllite makes it easy for you and others to join shared trips by paying only for the seats you occupy. Join like-minded travelers and experience private aviation in an accessible way.',
		imageUrl: '/assets/homepage/advantages-join-shared-flights.webp',
		imageWidth: 304,
		imageHeight: 320,
		id: '3',
	},
] as AdvantageProps[];

export const Advantages: FC = () => (
	<Element name="advantages">
		<Box sx={{ maxWidth: '840px' }} marginBottom={{ xs: '48px', md: '68px' }} id="advantages">
			<CustomSubTitle data-testid="homepage-advantages-section-title">
				Fllite Advantages
			</CustomSubTitle>
			{advantageItems.map((advantageItem) => (
				<AdvantagesItem key={advantageItem.id} {...advantageItem} />
			))}
			<Box sx={{ width: '100%' }} textAlign={{ xs: 'left', md: 'center' }}>
				<RequestAFlightButton />
			</Box>
		</Box>
	</Element>
);
