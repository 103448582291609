import { FC } from 'react';
import * as React from 'react';

import styled from '@emotion/styled';
import Slider from 'react-slick';

import { Icon } from '@fllite-fe/shared/src/components/Icon';

interface CarouselProps {
	children: React.ReactNode;
	setCurrentIndex: (current: number) => void;
	numberOfFlights?: number;
	onDarkBackground?: boolean;
	hideArrowsOnMobile?: boolean;
}

interface ArrowProps {
	className?: string;
	prev?: boolean;
	onClick?: () => void;
}

interface SliderWrapperProps {
	numberOfFlights?: number;
	onDarkBackground?: boolean;
	hideArrowsOnMobile?: boolean;
}

const SliderWrapper = styled('div')<SliderWrapperProps>`
	position: relative;
	max-width: ${({ theme }) => theme.dimensions.pageWidth};
	padding-left: 0;
	display: ${({ numberOfFlights }) => (numberOfFlights && numberOfFlights < 2 ? 'none' : 'block')};

	.jumpOnFlights .slick-list {
		overflow: visible;
		margin: 0 -10px;
	}

	.jumpOnFlights .slick-track {
		display: flex;
		margin-left: 0;
	}

	.jumpOnFlights .slick-slider {
		position: static;
	}

	.jumpOnFlights .slick-dots li {
		position: relative;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		border: none;
		cursor: pointer;

		:hover {
			background-color: ${({ theme }) => theme.colors.primaryDarkest};
		}
	}
	.jumpOnFlights .slick-dots li.slick-active {
		display: inline-block;
		background-color: ${({ theme, onDarkBackground }) =>
			onDarkBackground ? 'white' : theme.colors.primaryDarkest};
	}

	.jumpOnFlights .slick-slide {
		margin: 0 10px;

		${({ theme }) => theme.breakpoints.up('sm')} {
			margin: 0 10px;
		}
	}

	${({ theme }) => theme.breakpoints.up('sm')} {
		padding-left: 10px;
	}

	${({ theme }) => theme.breakpoints.up('sm')} {
		display: ${({ numberOfFlights }) =>
			numberOfFlights && numberOfFlights < 3 ? 'none' : 'block'};
	}

	${({ theme }) => theme.breakpoints.up('md')} {
		display: ${({ numberOfFlights }) =>
			numberOfFlights && numberOfFlights < 4 ? 'none' : 'block'};
	}

	.slick-arrow {
		opacity: ${({ hideArrowsOnMobile }) => (hideArrowsOnMobile ? 0 : 1)};
		${({ theme }) => theme.breakpoints.up('sm')} {
			opacity: 1;
		}
	}
`;

const StyledIcon = styled(Icon)<{ prev?: boolean }>`
	transform: ${({ prev }) => (prev ? 'rotate(90deg)' : 'rotate(-90deg)')};
`;

const ArrowWrapper = styled('div')<{ prev?: boolean }>`
	width: 52px;
	height: 52px;
	box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
	border-radius: 50%;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: absolute;
	right: ${({ prev }) => (prev ? 'auto' : '-145px')};
	left: ${({ prev }) => (prev ? '-145px' : 'auto')};
	top: 43%;
	transform: translateY(-50%);
	z-index: 2;

	&:hover {
		background: #fff;
	}

	:before {
		display: none;
	}

	&.slick-disabled {
		display: none;
	}

	${({ theme }) => theme.breakpoints.down('xl')} {
		right: ${({ prev }) => (prev ? 'auto' : '-65px')};
		left: ${({ prev }) => (prev ? '-65px' : 'auto')};
	}

	${({ theme }) => theme.breakpoints.down('lg')} {
		right: ${({ prev }) => (prev ? 'auto' : '10px')};
		left: ${({ prev }) => (prev ? '10px' : 'auto')};
	}

	${({ theme }) => theme.breakpoints.down('md')} {
		right: ${({ prev }) => (prev ? 'auto' : '15px')};
		left: ${({ prev }) => (prev ? '15px' : 'auto')};
	}
`;

const DotsWrapper = styled('div')`
	margin: 0 -40px;

	${({ theme }) => theme.breakpoints.up('md')} {
		width: ${({ theme }) => theme.dimensions.pageWidth};
		margin: 0 -20px;
	}
`;

const StyledList = styled('ul')`
	margin: 0px;
`;

interface StyledDotProps {
	onDarkBackground?: boolean;
}

const StyledDot = styled('div')<StyledDotProps>`
	width: 12px;
	height: 12px;
	border-radius: 50%;
	border: 1px solid
		${({ theme, onDarkBackground }) => (onDarkBackground ? 'white' : theme.colors.primaryDarkest)};
`;

const StyledSlider = styled(Slider)`
	padding-bottom: 50px;
`;

const Arrow: FC<ArrowProps> = ({ onClick, className, prev }) => (
	<ArrowWrapper onClick={onClick} className={className} prev={prev}>
		<StyledIcon type="dropdown-blue" prev={prev} />
	</ArrowWrapper>
);

export const UniversalCarousel: FC<CarouselProps> = ({
	children,
	setCurrentIndex,
	numberOfFlights,
	onDarkBackground,
	hideArrowsOnMobile,
}) => {
	const settings = {
		dots: true,
		appendDots: (dots) => (
			<DotsWrapper>
				<StyledList> {dots} </StyledList>
			</DotsWrapper>
		),
		customPaging: (i) => <StyledDot key={i + 1} onDarkBackground={onDarkBackground} />,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		variableWidth: true,
		nextArrow: <Arrow />,
		prevArrow: <Arrow prev />,
		className: 'jumpOnFlights',
		responsive: [
			{
				breakpoint: 421,
				settings: {
					variableWidth: false,
					nextArrow: null,
					prevArrow: null,
				},
			},
		],
		beforeChange: (current, next) => setCurrentIndex(next),
	};

	return (
		<SliderWrapper
			numberOfFlights={numberOfFlights}
			onDarkBackground={onDarkBackground}
			hideArrowsOnMobile={hideArrowsOnMobile}
		>
			<StyledSlider {...settings}>{children}</StyledSlider>
		</SliderWrapper>
	);
};
