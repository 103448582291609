import React, { FC, useState } from 'react';

import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';
import { Clouds, CloudWrapper, StyledCloud } from 'components/LandingPages/LandingHeading.styles';

import { UniversalCarousel } from '@fllite-fe/shared/src/components/Carousel/UniversalCarousel';
import { muiTheme } from '@fllite-fe/shared/src/muiTheme';

import { FeedbackItem } from './FeedbackItem';
import { SubTitle } from './shared';

const StyledTitle = styled(SubTitle)`
	font-size: 24px;
	text-align: center;

	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: 36px;
	}
`;

const Wrapper = styled('div')`
	position: relative;
	margin-bottom: 80px;
	${({ theme }) => theme.breakpoints.up('md')} {
		margin-bottom: 0;
	}
`;

const CarouselWrapper = styled('div')`
	position: relative;
	z-index: 10;
	max-width: 90vw;
	${({ theme }) => theme.breakpoints.up('md')} {
		max-width: 100%;
	}
`;

export interface FeedbackContent {
	airplanePhotoUrl: string;
	authorName: string;
	authorPhotoUrl?: string;
	content: string;
	flyType: string;
	stars?: 1 | 2 | 3 | 4 | 5;
	id: string;
}

const feedbackContent = [
	{
		airplanePhotoUrl: '/assets/homepage/feedback-airplane1.webp',
		authorName: 'Michelle W.',
		content: 'The flight and everyone was so great. Will definitely be doing it again!',
		flyType: 'Flew with a Turboprop King Air 200',
		id: '1',
	},
] as FeedbackContent[];

export const Feedback: FC = () => {
	const isDesktopMatch = useMediaQuery(muiTheme.breakpoints.up('sm'));

	return (
		<Wrapper>
			<StyledTitle>What our Passengers’ say about Fllite</StyledTitle>

			{feedbackContent.length > 1 ? (
				<MultiFeedbackWrapper feedbacks={feedbackContent} />
			) : (
				<SingleFeedbackWrapper feedbacks={feedbackContent} />
			)}
			{isDesktopMatch && (
				<Clouds>
					<CloudWrapper style={{ left: '-50%', marginTop: '-8%' }}>
						<StyledCloud style={{ width: '1000px' }} />
					</CloudWrapper>
				</Clouds>
			)}
		</Wrapper>
	);
};

interface WrapperFeedbackProps {
	feedbacks: FeedbackContent[];
}

const MultiFeedbackWrapper: FC<WrapperFeedbackProps> = ({ feedbacks }) => {
	const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

	return (
		<CarouselWrapper>
			<UniversalCarousel setCurrentIndex={setCurrentSlideIndex} onDarkBackground hideArrowsOnMobile>
				{feedbacks.map((feedbackItem, index) => (
					<FeedbackItem
						key={feedbackItem.id}
						feedbackItem={feedbackItem}
						isFocused={index === currentSlideIndex}
					/>
				))}
			</UniversalCarousel>
		</CarouselWrapper>
	);
};

const SingleFeedbackWrapper: FC<WrapperFeedbackProps> = ({ feedbacks }) => {
	const firstFeedback = feedbacks[0];
	if (!firstFeedback) return null;
	return <FeedbackItem feedbackItem={firstFeedback} isFocused />;
};
