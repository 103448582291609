import { FC } from 'react';

import styled from '@emotion/styled';
import { RequestAFlightButton } from 'components/LandingPages/RequestAFlightButton';

import { Benefits } from './Benefits';
import { Description } from './Description';
import { Feedback } from './Feedback';
import { Row, RowContent } from './shared';

const CustomRow = styled(Row)`
	background: linear-gradient(137.87deg, #002553 29.88%, #0056be 78.66%);

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 15%;
		background: linear-gradient(180deg, rgba(237, 240, 244, 0) 0%, #edf0f4 100%);
	}
`;

const RequestAFlightButtonWrapper = styled('div')`
	text-align: center;
	${({ theme }) => theme.breakpoints.up('md')} {
		margin-top: 48px;
		text-align: left;
	}
`;

export const BluePart: FC = () => {
	return (
		<CustomRow>
			<RowContent>
				<Benefits />
				<Feedback />
				<RequestAFlightButtonWrapper>
					<RequestAFlightButton />
				</RequestAFlightButtonWrapper>
				<Description />
			</RowContent>
		</CustomRow>
	);
};
