import { FC, useState } from 'react';

import styled from '@emotion/styled';
import { Form, HeadText, SubTitle, Title } from 'components/LandingPages/LandingHeading.styles';
import { useRouter } from 'next/router';

import { getContextForApp, useAuthentication } from '@fllite-fe/shared';
import { RequestAFlightApp } from '@fllite-fe/shared/src/components/RequestAFlightApp';
import { RaF } from '@fllite-fe/shared/src/components/RequestAFlightEmbedded/RaF';
import { saveRafFormValues } from '@fllite-fe/shared/src/utils/rafFormValues';

import { Row, RowContent } from './shared';

const CustomRow = styled(Row)`
	background: rgba(0, 37, 83, 0.7);
	background: transparent url('/assets/hp-header-background.webp') top center no-repeat;
	background-size: cover;

	${({ theme }) => theme.breakpoints.up('sm')} {
		margin-top: 52px;
		padding: 28px;
	}

	${({ theme }) => theme.breakpoints.up('md')} {
		padding: 48px 20px 80px;
	}

	&::after {
		background: linear-gradient(3deg, #002553 26.16%, rgba(0, 37, 83, 0) 60.28%);
		content: '';
		position: absolute;
		bottom: 0;
		height: 30%;
		width: 100%;
	}
`;

export const WelcomeForm: FC = () => {
	const { push } = useRouter();
	const { user } = useAuthentication();
	const [isRequestingFlight, setIsRequestingFlight] = useState(false);
	const { clientSectionUrl } = getContextForApp();

	const handleFormSubmit = (values) => {
		setIsRequestingFlight(true);
		saveRafFormValues(values);
		push(`${clientSectionUrl}/request-a-flight`);
	};

	return (
		<CustomRow>
			<RowContent>
				<HeadText>
					<Title data-testid="h1-title">Book a private aircraft in minutes</Title>
					<SubTitle data-testid="p-4step" color="">
						With Fllite, you can book charter flights in just 4 easy steps.
					</SubTitle>
				</HeadText>
				<Form>
					{!!user ? (
						<RequestAFlightApp isHomepage />
					) : (
						<RaF onSubmit={handleFormSubmit} isLoading={isRequestingFlight} isHomepage />
					)}
				</Form>
			</RowContent>
		</CustomRow>
	);
};
