import { Page } from 'components/Page';
import { BluePart } from 'components/PageIndex/BluePart';
import { Footer } from 'components/PageIndex/Footer';
import { WelcomeForm } from 'components/PageIndex/WelcomeForm';
import { WhitePart } from 'components/PageIndex/WhitePart';
import Head from 'next/head';

import { Layout } from '@fllite-fe/shared/src/components/Layout';

const PageIndex = () => (
	<Layout>
		<Head>
			<title>Fllite</title>
		</Head>
		<Page hasNavigationBackground>
			<WelcomeForm />
			<BluePart />
			<WhitePart />
			<Footer />
		</Page>
	</Layout>
);

export default PageIndex;
